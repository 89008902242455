import type { GetStaticProps, NextPage } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import { getDealsAPI, useCallMatrixFromFE, useGetDeals } from 'lib/actions'

import { DealsSlider } from 'components/molecules/sliders'
import { Head } from 'components/organisms'

const Page: NextPage = (data: any) => {
  const { meta } = data
  const { deals } = useGetDeals()

  const router = useRouter()

  const { callMatrixFromFE } = useCallMatrixFromFE()

  const handleMatrixCall = async (metricName: string, tags: string[]) => {
    const response = await callMatrixFromFE({ metricName, tags })
    console.log('data', response)
  }

  useEffect(() => {
    handleMatrixCall('web.errors.ErrorPage', [`type:404Page`])
  }, [])

  return (
    <div className='page'>
      <Head {...meta} />
      <section className='flex items-center justify-center bg-white py-[40px] md:py-[80px] px-[16px] '>
        <div className='container flex flex-col items-center lg:!max-w-[724px]'>
          <i className='nzsbi-question-double-lined-rounded text-[111px] text-O-500' />
          <div className='text-h1 font-display mb-[8px] text-center uppercase'>
            Oops! Page Not Found
          </div>
          <div className='text-base font-medium text-N-500 mb-[4px] text-center'>
            Apologies, we could not find the page that you are looking for.
          </div>
          <div className='text-base font-medium text-N-500 mb-[20px] text-center'>
            Please try your search again, if the issue persists please check the spelling of your
            search term or if you are pasting a URL into your browser check that this is correct.
          </div>
          <div className='text-base font-medium text-N-500 mb-[20px] text-center'>
            Alternatively check out our latest deals below or go back to our home page.
          </div>
          <Link href='/' className='text-primary-500 hover:text-primary-600'>
            <Button
              componentId='back-to-home-page'
              appearance='secondary-orange'
              iconBefore='nzsbi-chevron-left'>
              Back to Home Page
            </Button>
          </Link>
        </div>
      </section>

      {deals?.length > 0 && (
        <>
          <section className='container mt-[40px] mb-[32px]'>
            <div className='flex flex-col items-start lg:!flex-row lg:!justify-between'>
              <span className='font-semibold text-h3'>Our Deals</span>
              <Button
                componentId='all-deals'
                onClick={() => {
                  router?.push('/deals')
                }}
                appearance='link'
                iconAfter='nzsbi-chevron-right'
                className='lg:ml-0'
                size='md'>
                All Deals
              </Button>
            </div>
          </section>

          <DealsSlider products={deals} isPromotions />
        </>
      )}
    </div>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const data = await getDealsAPI()
  return {
    props: {
      pageData: {
        products: data?.data?.products || []
      },
      meta: {
        title: '404 - Page Not Found',
        description: '404 - Page Not Found',
        keywords: '404 - Page Not Found'
      }
    }
  }
}

export default Page
