import React, { FC } from 'react'
import CN from 'classnames'
import { ISearchBarProduct } from 'lib/actions'

import { ProductCard } from 'components/molecules/cards/ProductCard'

export interface IDealsSliderProps {
  className?: string
  isPromotions: boolean
  products?: ISearchBarProduct[]
}

export const DealsSlider: FC<IDealsSliderProps> = ({
  className,
  isPromotions,
  products,
  ...restProps
}: IDealsSliderProps) => {
  const DealsSliderClasses = CN(
    `container deals-slider flex flex-row gap-[16px] lg:gap-[32px] xs:pl-[16px] md:pl-[40px] overflow-x-scroll overflow-y-clip scrollbar-hide mb-[80px]`,
    className
  )

  return (
    <div className={DealsSliderClasses} {...restProps}>
      {products?.map(product => {
        return (
          <ProductCard
            key={`${product.id}-product-card`}
            className='min-w-[293px] md:max-w-[296px]'
            product={product}
            appearance='vertical'
            isShowStock={!isPromotions}
            isShowStoreDetails={!isPromotions}
            isShowWishList={true}
          />
        )
      })}
    </div>
  )
}

export default DealsSlider
